.fixed, header { z-index: 2000; }

.image-article.bloc-accroche {
  width: 146px;
  margin-right: 10px;
}

.partners.contenu {
  width: 960px;
  margin: 0 auto;
}

footer .liensfooter li { padding: 0 55px; }